import styled from 'styled-components';

export const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Alinhar ao topo */
  height: 100vh; /* Sempre ocupar toda a altura da tela */
  width: 100%;
  padding: 0 20px;

  /* Adiciona um espaçamento fixo do topo */
  padding-top: 10%; /* Ajuste conforme necessário */

  @media (max-width: 1402px) {
    padding-top: 15%; /* Um pouco mais de espaço para telas menores */
  }

  @media (max-width: 768px) {
    padding-top: 15%; /* Um pouco mais de espaço para telas menores */
  }
  @media (max-width: 480px) {
    padding-top: 25%;
  }
  .Progresso {
    margin-left: -55%;
    @media (max-width: 896px) {
      margin-left: 0%;
    }
  }
`;
export const Container = styled.section`
  overflow: hidden;
  overflow-y: auto;
  transition: margin-left 0.5s ease;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1%;
  height: 100vh;
  z-index: 1;
  padding-left: 180px;

  @media (max-width: 1024px) {
    padding-left: 0px;
  }

  @media (max-width: 1024px) {
    padding-left: 0px;
  }
  background: #fff;

  .content {
    margin-top: 5%;
  }
`;
