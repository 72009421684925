import React from 'react';
import { Container } from '../../styles/GlobalStyles';

export default function Certificaiton() {
  return (
    <Container
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', // Alinha o conteúdo verticalmente no centro
        height: '100vh',
        paddingTop: '50px',
        paddingBottom: '50px', // Adiciona espaço nas partes superior e inferior
      }}
    >
      <div
        style={{
          position: 'relative',
          width: '100%',
          maxWidth: '960px', // Limita a largura máxima
          height: '100%',
          paddingBottom: '56.25%', // Proporção 16:9
          overflow: 'hidden',
          margin: '0 20px', // Margem lateral
        }}
      >
        <iframe
          style={{
            position: 'absolute',
            top: '15%',
            left: '0',
            width: '100%',
            height: '50%',
          }}
          src="https://www.youtube.com/embed/QuU9lqH7EGw?start=9"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </Container>
  );
}
