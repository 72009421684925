import React from 'react';
import { Switch } from 'react-router-dom';

import MyRoute from './MyRoute';
import PrivateRoute from './PrivateRoutes';

import EmpEdit from '../pages/EmpEdit';
import AdmEdit from '../pages/AdmEdit';
import AdmEditEmp from '../pages/AdmEditEmp';
import HomeAdmin from '../pages/HomeAdmin';
import HomeEmpresa from '../pages/HomeEmpresa';
import HomeColaborador from '../pages/HomeCollaborator';
import AdmPainel from '../pages/PainelADM';
import EmpPainel from '../pages/PainelEmpresa';
import Register from '../pages/Register';
import Login from '../pages/Login';
import Page404 from '../pages/Page404';
import Recovery from '../pages/Recovery';
import PasswordRecoveryPage from '../pages/RecoveryPassword';
import Edit from '../pages/EditUser/index';
import AdminDashboard from '../pages/admin';
import Confirm from '../pages/ConfirmAccount';
import Course from '../pages/Course';
import UserCourse from '../pages/UserCourse';
import NewCourse from '../pages/NewCourse';
import CourseManager from '../pages/CourseManager';
import NewSubmodule from '../pages/NewSubmodule';
import SubmoduleManager from '../pages/SubmoduleManager';
import NewQuiz from '../pages/NewQuiz';
import QuizManager from '../pages/QuizManager';
import CoursePermission from '../pages/CoursePermission';
import CourseAcces from '../pages/CourseAcces';
import NewContent from '../pages/NewContent';
import ContentManager from '../pages/ContentManager';
import MyCourse from '../pages/MyCourse';
import EmpPermission from '../pages/EmpPermission';
import Execute from '../pages/Execute';
// import Execute from '../pages/ExecuteCourse/Accordion';
import Training from '../pages/Training';
import LandingPage from '../pages/Home';
import Management from '../pages/Management';
import Sectors from '../pages/Sectors';
import Funcoes from '../pages/Funcao';
import ProfileImg from '../pages/ProfileImg';
import News from '../pages/News';
import Certification from '../pages/Certification';
import Suport from '../pages/Suport';
import SuportColab from '../pages/SuportColabPremium';
import SuportEmp from '../pages/SuportEmp';
import CertificationEmp from '../pages/CertificationEmp';
import CreateAccount from '../pages/createAccount';
import AdmEditUsers from '../pages/AdmEditUsers';
import PdfViewer from '../pages/PdfViewer';
import Document from '../pages/DocumentManagement';
import CompanyDocuments from '../pages/CompanyDocuments';
import RelatorioPage from '../pages/RelatorioPage';
import SuportEmpPremium from '../pages/SuportEmpPremium';
import Quizz from '../pages/ExecuteCourse/Quizz';

export default function Routes() {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/home"
        permittedDepartments={['colaborador', 'colaborador + Premium']}
        component={HomeColaborador}
      />
      <PrivateRoute
        exact
        path="/training"
        permittedDepartments={['colaborador', 'colaborador + Premium']}
        component={Training}
      />
      <PrivateRoute
        exact
        path="/user-course/:id"
        permittedDepartments={['colaborador', 'colaborador + Premium']}
        component={UserCourse}
      />
      <PrivateRoute
        exact
        path="/execute/:id"
        permittedDepartments={['colaborador', 'colaborador + Premium']}
        component={Execute}
      />
      {/* <PrivateRoute
        exact
        path="/execute/:id"
        permittedDepartments={['colaborador', 'colaborador + Premium']}
        component={Execute}
      /> */}
      <PrivateRoute
        exact
        path="/certification"
        permittedDepartments={['colaborador', 'colaborador + Premium']}
        component={Certification}
      />
      <PrivateRoute
        exact
        path="/suport"
        permittedDepartments={['colaborador', 'colaborador + Premium']}
        component={Suport}
      />
      <PrivateRoute
        exact
        path="/CompanyDocuments"
        permittedDepartments={['colaborador + Premium']}
        component={CompanyDocuments}
      />
      <PrivateRoute
        exact
        path="/SuportColab"
        permittedDepartments={['colaborador + Premium']}
        component={SuportColab}
      />

      <PrivateRoute exact path="/admin" permittedDepartments={['Admin']} component={AdminDashboard} />
      <PrivateRoute exact path="/admin-painel" permittedDepartments={['Admin']} component={AdmPainel} />
      <PrivateRoute exact path="/home-admin" permittedDepartments={['Admin']} component={HomeAdmin} />
      <PrivateRoute exact path="/new-course" permittedDepartments={['Admin']} component={NewCourse} />
      <PrivateRoute exact path="/new-content/:id" permittedDepartments={['Admin']} component={NewContent} />
      <PrivateRoute exact path="/course-permission" permittedDepartments={['Admin']} component={CoursePermission} />
      <PrivateRoute exact path="/courseAcces/:id/edit" permittedDepartments={['Admin']} component={CourseAcces} />
      <PrivateRoute exact path="/new-submodule/:id" permittedDepartments={['Admin']} component={NewSubmodule} />
      <PrivateRoute exact path="/course" permittedDepartments={['Admin']} component={Course} />
      <PrivateRoute exact path="/adm/:id/edit" permittedDepartments={['Admin']} component={AdmEdit} />
      <PrivateRoute exact path="/courseManager/:id/edit" permittedDepartments={['Admin']} component={CourseManager} />
      <PrivateRoute
        exact
        path="/submoduleManager/:id/edit"
        permittedDepartments={['Admin']}
        component={SubmoduleManager}
      />
      <PrivateRoute
        exact
        path="/contentSubmoduleManager/:id/edit"
        permittedDepartments={['Admin']}
        component={ContentManager}
      />
      <PrivateRoute exact path="/quizManager/:id/edit" permittedDepartments={['Admin']} component={QuizManager} />
      <PrivateRoute exact path="/new-quiz/:id" permittedDepartments={['Admin']} component={NewQuiz} />
      <PrivateRoute exact path="/adm/:id/users" permittedDepartments={['Admin']} component={AdmEditUsers} />
      <PrivateRoute exact path="/adm/:id/emp" permittedDepartments={['Admin']} component={AdmEditEmp} />

      <PrivateRoute
        exact
        path="/register"
        permittedDepartments={['Empresa', 'Empresa + Premium']}
        component={Register}
      />
      <PrivateRoute
        exact
        path="/mycourse"
        permittedDepartments={['Empresa', 'Empresa + Premium']}
        component={MyCourse}
      />
      <PrivateRoute
        exact
        path="/management"
        permittedDepartments={['Empresa', 'Empresa + Premium']}
        component={Management}
      />
      <PrivateRoute exact path="/sectors" permittedDepartments={['Empresa', 'Empresa + Premium']} component={Sectors} />
      <PrivateRoute exact path="/funcoes" permittedDepartments={['Empresa', 'Empresa + Premium']} component={Funcoes} />
      <PrivateRoute
        exact
        path="/suportEmp"
        permittedDepartments={['Empresa', 'Empresa + Premium']}
        component={SuportEmp}
      />
      <PrivateRoute
        exact
        path="/home-empresa"
        permittedDepartments={['Empresa', 'Empresa + Premium']}
        component={HomeEmpresa}
      />
      <PrivateRoute exact path="/Document" permittedDepartments={['Empresa + Premium']} component={Document} />
      <PrivateRoute
        exact
        path="/empresa-painel"
        permittedDepartments={['Empresa', 'Empresa + Premium']}
        component={EmpPainel}
      />
      <PrivateRoute
        exact
        path="/emp/:id/edit"
        permittedDepartments={['Empresa', 'Empresa + Premium']}
        component={EmpEdit}
      />
      <PrivateRoute
        exact
        path="/empAccess/:id/edit"
        permittedDepartments={['Empresa', 'Empresa + Premium']}
        component={EmpPermission}
      />
      <PrivateRoute
        exact
        path="/certificationEmp"
        permittedDepartments={['Empresa', 'Empresa + Premium']}
        component={CertificationEmp}
      />
      <PrivateRoute
        exact
        path="/relatorioPage"
        permittedDepartments={['Empresa + Premium']}
        component={RelatorioPage}
      />
      <PrivateRoute
        exact
        path="/SuportEmpPremium"
        permittedDepartments={['Empresa + Premium']}
        component={SuportEmpPremium}
      />
      <PrivateRoute
        exact
        path="/quizz/:id"
        permittedDepartments={['colaborador', 'Empresa + Premium']}
        component={Quizz}
      />

      <MyRoute exact path="/login" component={Login} isClosed={false} />
      <MyRoute exact path="/CreateAccount" component={CreateAccount} isClosed={false} />

      <MyRoute exact path="/" component={LandingPage} isClosed={false} />
      <MyRoute exact path="/confirm/:token" component={Confirm} />
      <MyRoute exact path="/recuperar-senha" component={Recovery} isClosed={false} />
      <MyRoute path="/recovery" component={PasswordRecoveryPage} />
      <MyRoute exact path="/edit/" component={Edit} isClosed />
      <MyRoute exact path="/news" component={News} isClosed />
      <MyRoute exact path="/profile-img/" component={ProfileImg} isClosed />
      <MyRoute path="/termo" component={PdfViewer} />
      <MyRoute path="*" component={Page404} />
    </Switch>
  );
}
